.faq-section {
    padding: 5px 0;
    padding-bottom: 75px;
    text-align: center;
    background-color: #0f0f0f;
    color: #fff;
    font-family: "Roboto", sans-serif;
}

.faq-section h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.faq-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 5px 50px;
}

.faq-item {
    margin-bottom: 8px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.5s ease;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
}

.faq-question {
    background-color: #151515;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #fff;
    transition: all 0.5s ease;
    text-align: left;
    font-weight: 500;
}

.faq-answer {
    text-align: left;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    background-color: #181818;
    color: #cbcbcb;
    border-top: #c40711 solid 1.5px;
}

.faq-answer b {
    color: #fff;
}

.faq-question i {
    transition: transform 0.5s ease, color 0.5s ease;
}

.faq-question-text {
    padding-right: 10px;
}

.faq-item.active .faq-question i {
    transform: rotate(180deg);
}

.faq-answer p {
    padding: 0px 15px;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.faq-answer a {
    color: #7289da;
    font-weight: bolder;
    text-decoration: none;
}

.faq-item.active .faq-answer {
    max-height: 160px;
}

.faq-item.active .faq-answer p {
    opacity: 1;
}

.fas {
    transition: all 0.5s ease;
}

.faq-item .fa-plus {
    color: #fff;
}

.faq-item.active .fa-times {
    color: #fff;
}
