.custom-mods-section {
    padding: 5px 20px;
    padding-bottom: 50px;
    background-color: #181818;
    text-align: center;
    font-family: "Roboto", sans-serif;
}

.custom-mods-section h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 0;
    text-transform: uppercase;
}

.custom-mods-section p {
    font-size: 16px;
    color: #a0a0a0;
    margin-bottom: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    padding-bottom: 15px;
    border-bottom: #c40711 solid 2px;
}

.mods-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    max-width: 1400px;
    margin: 0 auto;
}

.mod-item {
    background-color: transparent;
    transition: transform 0.3s ease;
    flex: 0 1 250px;
}

.mod-item img {
    border-radius: 5px;
    max-width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.mod-item:hover {
    transform: translateY(-5px);
}

@media screen and (max-width: 768px) {
    .custom-mods-section h2 {
        font-size: 38px;
    }
    .mod-item {
        flex: 0 1 180px;
    }
}

@media screen and (max-width: 400px) {
    .custom-mods-section h2 {
        font-size: 35px;
    }
    .mod-item {
        flex: 0 1 160px;
    }
}

@media screen and (max-width: 360px) {
    .custom-mods-section h2 {
        font-size: 34px;
    }
    .mod-item {
        flex: 0 1 155px;
    }
}

@media screen and (max-width: 345px) {
    .custom-mods-section h2 {
        font-size: 32px;
    }
    .mod-item {
        flex: 0 1 145px;
    }
}