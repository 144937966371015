.main-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('images/homeBack.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
}

.main-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
}

.main-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    z-index: 2;
}

.content {
    color: #fff;
    padding: 15px;
    width: 100%;
    max-width: 600px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content h1 {
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 20px;
}

.content p {
    font-size: 18px;
    color: #a0a0a0;
    margin-bottom: 20px;
}

.cta-button {
    padding: 15px 30px;
    font-size: 18px;
    color: #fff;
    background-color: #c40711;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s;
    margin: 0 auto;
    display: block;
}

.image {
    max-width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modsVideo {
    width: 100%;
    border-radius: 5px;
}

.image img {
    width: 100%;
    border-radius: 5px;
}

.scroll-indicator {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    color: #cbcbcb;
    animation: bounce 2s infinite;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.scroll-indicator.hidden {
    opacity: 0;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(-50%) translateY(0);
    }
    40% {
        transform: translateX(-50%) translateY(-10px);
    }
    60% {
        transform: translateX(-50%) translateY(-5px);
    }
}

@media screen and (max-width: 1280px) {
    .image {
      visibility: hidden;
      display: none;
    }

    .main-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .content {
        text-align: center;
        width: 80%;
        max-width: 500px;
    }

    .steambutton {
        margin: 20px auto;
        display: block;
        width: 100%;
        max-width: 300px;
    }
}

.steambutton {
    background-color: #c40711;
    width: 300px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: transparent;
    margin: 0 auto;
}

.steambutton span {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    width: 75%;
    font-size: 15px;
    text-transform: uppercase;
    left: 0;
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.steambutton span,
.steambutton .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
}

.steambutton .icon .fa {
    font-size: 30px;
    line-height: 50px;
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.steambutton .icon {
    width: 25%;
    right: 0;
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.steambutton span:after {
    content: '';
    background-color: white;
    width: 2px;
    height: 70%;
    position: absolute;
    top: 15%;
    right: -1px;
}

.steambutton.success span,
.steambutton:hover span {
    left: -72%;
    opacity: 0;
}

.steambutton.success .icon,
.steambutton:hover .icon {
    width: 100%;
}

.steambutton.success .icon .fa,
.steambutton:hover .icon .fa {
    font-size: 45px;
}
