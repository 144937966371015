.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    align-content: center;
}

.logo img {
    padding-left: 15px;
    margin-top: -25px;
    height: 125px;
}

.nav {
    display: flex;
    align-items: center;
}

.nav a {
    padding-right: 75px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 25px;
    padding: 8px 15px;
    margin-top: -25px;
}

.nav a:hover {
    color: #c40711;
    transition: color 0.3s ease;
}

.menu-icon {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    cursor: pointer;
}

.menu-bar {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin-right: 5px;
    margin-bottom: -45px;
}

@media (max-width: 768px) {
    .nav {
        position: fixed;
        top: 0;
        right: 0;
        flex-direction: column;
        background-color: rgba(15, 15, 15, 0.85);
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .nav.open {
        display: flex;
    }

    .nav a {
        margin: 15px 0;
        padding: 10px 20px;
        width: 80%;
        text-align: center;
        font-size: 32px;
        font-weight: 1000;
    }

    .menu-icon {
        display: flex;
        position: absolute;
        right: 20px;
        top: 3px;
    }

    .main-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .content {
        text-align: center;
        width: 80%;
        max-width: 500px;
    }
}
